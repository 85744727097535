import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  AbstractTexture,
  Opacity,
} from '../../components/AbstractTexture/AbstractTexture';
import {Button} from '../../components/Button/Button';
import {Color, Logo} from '../../components/Logo/Logo';
import {MediaQueriesResults} from '../../components/MediaQueries';
import {useBreakpoint} from '../../hooks/breakpoint';
import './Home.css';

export const Home: React.FC = () => {
  const breakpoints = useBreakpoint<MediaQueriesResults>();
  console.group('media queries');
  Object.keys(breakpoints).forEach(media =>
    console.log(`${media} ---- ${breakpoints[media] ? 'Yes' : 'No'}`)
  );
  console.groupEnd();
  return (
    <section className="Home">
      <AbstractTexture
        opacity={breakpoints.medium ? Opacity.Half : Opacity.Low}
      >
        <div className="Home-wrapper">
          <div className="Home-logo">
            <Logo color={Color.White} />
          </div>
          <h1 className="Home-title">Consulting et développement logiciel</h1>
          <p className="Home-description">
            Pour aider votre entreprise à augmenter sa productivité et créer de
            la valeur en optimisant vos process et en améliorant la qualité de
            votre code.
          </p>
          <AnchorLink href="#Services" offset="70">
            <Button className="Home-button" text="DÉCOUVRIR MES SERVICES" />
          </AnchorLink>
        </div>
      </AbstractTexture>
    </section>
  );
};
