import React, {useState} from 'react';
import './Services.css';
import {ServiceType} from './ServiceType';

const services = [
  {
    title: 'Automatisation',
    features: [
      {icon: 'assignment.svg', name: 'Automatisation des tests'},
      {
        icon: 'build.svg',
        name:
          'Automatisation des tâches répétitives, du développement à la mise en production',
      },
      {
        icon: 'book.svg',
        name:
          'Automatisation de la vérification des conventions de code de votre équipe',
      },
    ],
  },
  {
    title: 'Infrastructure',
    features: [
      {
        icon: 'cached.svg',
        name:
          'Mise en place d’un processus d’intégration et de déploiement continu',
      },
      {
        icon: 'cloud.svg',
        name:
          'Poser les premières base d’une infrastructure cloud (AWS ou Google Cloud)',
      },
      {icon: 'visibility.svg', name: 'Monitoring de vos applications'},
    ],
  },
  {
    title: 'Mentoring',
    features: [
      {icon: 'bug_report.svg', name: 'Audit de code'},
      {
        icon: 'assignment_turned_in.svg',
        name: 'Écriture et formation à l’écriture de tests',
      },
      {icon: 'create_new_folder.svg', name: 'Recommandations d’architecture'},
    ],
  },
  {
    title: 'Développement',
    features: [
      {icon: 'format.svg', name: 'Refactoring'},
      {icon: 'web.svg', name: 'Preuve de concept / prototypage'},
      {icon: 'keyboard.svg', name: 'Développement back-end et front-end'},
    ],
  },
];

const ServiceList: React.FC = ({children}) => {
  return <ul className="Services-list">{children}</ul>;
};

export const Services: React.FC = () => {
  const [referenceZindex, setReferenceZIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <section className="Services" id="Services">
      <h2 className="Services-title">Services</h2>
      <p className="Services-description">
        Développeur freelance, je suis doté d'une forte sensibilité infra et
        fonctionnelle qui me permet de saisir tous les enjeux de votre projet.
      </p>
      <ServiceList>
        {services.map((service, index) => (
          <ServiceType
            title={service.title}
            key={index}
            referenceZindex={referenceZindex}
            updateReferenceZIndex={setReferenceZIndex}
            isActive={activeIndex === index}
            setAsActive={() => setActiveIndex(index)}
          >
            {service.features}
          </ServiceType>
        ))}
      </ServiceList>
    </section>
  );
};
