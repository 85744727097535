import React, {Fragment} from 'react';
import {CircleIcon, Size, Theme} from '../../components/CircleIcon/CircleIcon';
import './WorkTogether.css';

const workSteps = [
  {
    title: 'Compréhension du besoin',
    description:
      'Échanger avec vous me permet de comprendre votre besoin métier et ensuite de le traduire techniquement.',
  },
  {
    title: 'Proposition de solution',
    description:
      'Je travaille main dans la main avec vous pour vous proposer la meilleure solution avec un devis et un planning de mise en oeuvre.',
  },
  {
    title: 'Mise en place',
    description:
      'Un déploiement progressif me permet d’assurer une transition facile vers une nouvelle solution développée ou nouveau processus mis en place.',
  },
];

interface Props {
  index: number;
  title: string;
  description: string;
}

const WorkStep: React.FC<Props> = ({title, description, index}) => {
  const stepNumber = <strong className="Work-number">{index}</strong>;
  return (
    <li className="Work-step">
      <CircleIcon theme={Theme.BLUE} iconContent={stepNumber} size={Size.Big} />
      <h3 className="Work-stepText">{title}</h3>
      <p className="Work-stepText Work-subText">{description}</p>
    </li>
  );
};

const WorkStepSeparator: React.FC = () => {
  return (
    <li className="Work-stepSeparator Work-stepSeparator--hidden">
      <hr className="Work-stepSeparatorLine" />
    </li>
  );
};

export const WorkTogether: React.FC = () => (
  <section className="Work">
    <h2 className="Work-title">Travaillons ensemble</h2>
    <ol className="Work-list">
      {workSteps.map((step, index) => (
        <Fragment key={step.title}>
          {index > 0 ? <WorkStepSeparator /> : null}
          <WorkStep
            title={step.title}
            description={step.description}
            index={index + 1}
          />
        </Fragment>
      ))}
    </ol>
  </section>
);
