import React, {ReactNode} from 'react';
import {classNames, theme as themeConf} from '../utils';
import './CircleIcon.css';

export enum Theme {
  PURPLE = 'purple',
  BLUE = 'blue',
}

export enum Size {
  Small = 'small',
  Big = 'big',
  Huge = 'huge',
}

interface Props {
  iconContent: string | ReactNode;
  description?: string;
  backgroundColor?: string;
  size?: Size;
  theme?: Theme;
  className?: string;
}

export const CircleIcon: React.FC<Props> = ({
  iconContent,
  description,
  backgroundColor,
  size = Size.Big,
  theme = '',
  className = '',
}) => {
  const t = themeConf(theme, size);
  const style = backgroundColor ? {backgroundColor} : {};
  const klass = classNames(t('CircleIcon-icon'), className);

  return (
    <div className="CircleIcon-container">
      <div className={klass} style={style}>
        {iconContent}
      </div>
      {description ? (
        <span className="CircleIcon-text">{description}</span>
      ) : null}
    </div>
  );
};
