import React from 'react';
import {CircleIcon, Size} from '../../components/CircleIcon/CircleIcon';
import portrait from '../../images/Portait.jpg';
import './Whoami.css';

export const Whoami: React.FC = () => (
  <section className="Whoami">
    <div className="Whoami-container">
      <div className="Whoami-pictureWrapper">
        <CircleIcon
          size={Size.Huge}
          iconContent={
            <img className="Whoami-photo" src={portrait} alt="Kevyn Bruyere" />
          }
        />
      </div>
      <div className="Whoami-description">
        <p>
          Après plus de 5 années à travailler dans différentes entreprises
          (startup, éditeur, ESN), je me suis rendu compte que beaucoup
          d’entreprises perdaient du temps et de l’argent sur des actes
          répétitifs, des boucles de feedback trop longues ou des erreurs dues à
          un manque de développement de qualité.
        </p>
        <p>
          Je souhaite aider les entreprises à réaliser des économies grâce à
          l’automatisation, mais également en facilitant le travail de leurs
          développeurs au quotidien.
        </p>
        <p>
          La qualité et la maintenabilité du code est primordiale pour moi. J’ai
          à coeur de transmettre des bonnes pratiques aux personnes avec qui je
          collabore.
        </p>
      </div>
    </div>
  </section>
);
