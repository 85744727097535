import React from 'react';
import './Contact.scss';
import {ContactForm} from './ContactForm';

export const Contact: React.FC = () => {
  return (
    <section className="Contact" id="Contact">
      <h2 className="Contact-title">Contact</h2>
      <div className="Contact-description">
        <p className="Contact-descriptionBlock">
          Un projet ? Besoin d’un devis ? Contactez-moi, je réponds
          généralement sous 2 heures !
        </p>
        <p className="Contact-descriptionBlock">
          Je suis disponible pour travailler sur votre projet à distance.
        </p>
        <p className="Contact-descriptionBlock">
          Je peux également me déplacer, principalement en Savoie, Haute-Savoie
          et Lyon, ou ailleurs si nécessaire.
        </p>
      </div>
      <ContactForm />
    </section>
  );
};
