import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';
import logoOptimiz from '../../images/logo_optimiz.png';
import logoStormshield from '../../images/logo_stormshield.png';
import logoTrackin from '../../images/logo_trackin.svg';
import './Testimonials.css';
import 'swiper/swiper-bundle.min.css';
import {Testimony} from './Testimony';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export const Testimonials: React.FC = () => (
  <section className="Testimonials" id="Temoignages">
    <h2 className="Testimonials-title">Témoignages</h2>
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{clickable: true}}
      loop={true}
      autoplay={{delay: 5000}}
    >
      {testimonials.map((testimony, index) => (
        <SwiperSlide key={index}>
          <Testimony {...testimony} />
        </SwiperSlide>
      ))}
    </Swiper>
  </section>
);

const testimonials = [
  {
    description: [
      'Trackin propose un système de commandes en ligne et de gestion des livraisons pour les restaurateurs. Un dimanche soir, toute une partie de l’application (système de commande en ligne et le dashboard de gestion des livreurs) est tombée, pendant la période de service des restaurateurs.',
      ' Grâce à un système de monitoring mis en place par Kevyn, nous avons été prévenu en temps réel. Cela nous a permis d’appeler le client pour le prévenir avant même qu’il ne rencontre le problème.',
      {
        text:
          'Un problème technique qui aurait pu coûter à l’image de la société nous a au contraire permis de montrer notre réactivité et notre professionalisme !',
        bold: true,
      },
      'C’est un vrai investissement pour une entreprise de travailler avec un développeur qui sait anticiper ce type de problèmes.',
    ],
    companyLogo: logoTrackin,
    companyName: 'Trackin',
    person: {
      name: 'Kioumarse Komjani',
      occupation: 'Responsable commercial',
    },
  },
  {
    description: [
      'Nous développions chez Optimiz.me des logiciels SaaS à destination des professionnels du référencement naturel.',
      'Kevyn a mis en place, à son initiative, un système désactivant les machines non utilisées de notre infrastructure cloud, notamment celles utilisées pour le développement et le build de l’application lors de la nuit ou les weekends.',
      'Ce système était flexible, puisqu’il permettait quand même relancer des machines si un développeur avait besoin de travailler exceptionnellement sur ces places horaires.',
      {
        text:
          'Nous avons pu diviser par 5 les coûts de notre infrastructure liés au développement et au build, tout en gardant de la flexibilité en cas d’imprévu.',
        bold: true,
      },
      'Cette démarche de réduction des coûts est extrêmement précieuse pour une startup !',
    ],
    companyLogo: logoOptimiz,
    companyName: 'Optimiz.me',
    person: {
      name: 'Mehdi Coly',
      occupation: 'CEO',
    },
  },
  {
    description: [
      "Stormshield est le leader européen des solutions communicantes et intelligentes pour anticiper les attaques et protéger les infrastructures digitales. Filiale d'Airbus, elle est le résultat du rachat de différentes sociétés de sécurité informatique.",
      "Le rachat a été assez impactant pour la productivité des équipes de développement, il a fallu mettre en place des outils de développement, d'intégration continue et de communication communs. Notamment des outils comme Jenkins, Mattermost...",
      "Lorsque Kevyn a travaillé chez Stormshield, certains de ces outils n'étaient pas encore en place. Kevyn a alors fortement participé à améliorer la productivité des équipes de développement à cette période.",
      'Il a par exemple développé un bot qui alertait les développeurs et les testeurs du résultat de leur build suite à un commit.',
      {
        text:
          "Ils recevaient alors des notifications en temps réel afin de savoir si un build avait réussi ou non et ainsi savoir s'ils pouvaient avancer dans leur travail.",
        bold: true,
      },
      {
        text: 'Un gain de temps non négligeable !',
        bold: true,
      },
    ],
    companyLogo: logoStormshield,
    companyName: 'Stormshield',
    person: {
      name: 'Jérôme Guilloux',
      occupation: 'Responsable R&D',
    },
  },
];
