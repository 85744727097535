import React from 'react';
import {Button} from '../../components/Button/Button';
import './ContactForm.scss';

export const ContactForm: React.FC = () => {
  return (
    <form
      className="ContactForm"
      name="contact"
      method="POST"
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label className="ContactForm-label" htmlFor="name">
          Votre nom
        </label>
        <input
          placeholder=" "
          className="ContactForm-input"
          type="text"
          required
          id="name"
          name="name"
        />
      </p>
      <p>
        <label className="ContactForm-label" htmlFor="email">
          Votre email
        </label>
        <input
          placeholder=" "
          className="ContactForm-input"
          required
          type="email"
          id="email"
          name="email"
        />
      </p>
      <p>
        <label className="ContactForm-label ContactForm-tel" htmlFor="phone">
          Votre numéro de téléphone (facultatif)
        </label>
        <input
          placeholder=" "
          minLength={10}
          className="ContactForm-input"
          type="tel"
          id="phone"
          name="phone"
        />
      </p>
      <p>
        <label className="ContactForm-label" htmlFor="message">
          Votre message
        </label>
        <textarea
          placeholder=" "
          name="message"
          required
          className="ContactForm-input"
        />
      </p>
      <Button className="ContactForm-button" text="ENVOYER" type="submit" />
    </form>
  );
};
