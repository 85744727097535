import React, {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {classNames} from '../../components/utils';
import {useIcon} from '../../useIcon';
import './ServiceType.css';
import Chevron from '../../images/chevron_bottom.inline.svg';

interface Props {
  title: string;
  referenceZindex: number;
  updateReferenceZIndex: (zindex: number) => void;
  isActive: boolean;
  setAsActive: () => void;
  children: ReadonlyArray<{
    name: string;
    icon: string;
  }>;
}

const ServiceTypeEntry: React.FC<{name: string; icon: string}> = ({
  name,
  icon,
}) => {
  const iconNodeUrl = useIcon(icon);

  return (
    <li
      className="ServiceType-entry"
      style={iconNodeUrl ? {backgroundImage: `url(${iconNodeUrl})`} : {}}
    >
      <span className="ServiceType-name">{name}</span>
    </li>
  );
};

export const ServiceType: React.FC<Props> = ({
  title,
  referenceZindex,
  updateReferenceZIndex,
  isActive,
  setAsActive,
  children,
}) => {
  const [isOpen, toggleState] = useState(false);
  const [zIndex, setZindex] = useState(0);
  return (
    <li className="ServiceType">
      <h3
        className={classNames(
          'ServiceType-title',
          isActive && 'ServiceType-title--active'
        )}
        onClick={() => {
          toggleState(!isOpen);
          setAsActive();
          setZindex(referenceZindex + 1);
          updateReferenceZIndex(referenceZindex + 1);
        }}
      >
        {title}
        <Chevron
          className={classNames(
            'ServiceType-arrow',
            isOpen && 'ServiceType-arrow-bottom'
          )}
        />
      </h3>
      <AnimateHeight
        height={isOpen ? 'auto' : 0}
        className="ServiceType-animator"
        contentClassName="ServiceType-animatorContent"
        style={{zIndex}}
      >
        <ul className={'ServiceType-list'}>
          {children.map(task => (
            <ServiceTypeEntry {...task} key={task.name} />
          ))}
        </ul>
      </AnimateHeight>
    </li>
  );
};
