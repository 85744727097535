import React from 'react';
import {classNames} from '../../components/utils';
import './Testimony.css';

interface RichText {
  text: string;
  bold: boolean;
}

interface Props {
  description: Array<RichText | string>;
  companyLogo: string;
  companyName: string;
  person: {
    name: string;
    occupation: string;
  };
}

const Text: React.FC<{description: RichText | string}> = ({description}) => {
  if (typeof description === 'string') {
    return <p className="Testimony-text">{description}</p>;
  }

  return (
    <p
      className={classNames(
        'Testimony-text',
        description.bold && 'Testimony-text-bold'
      )}
    >
      {description.text}
    </p>
  );
};

export const Testimony: React.FC<Props> = ({
  person,
  description,
  companyLogo,
  companyName,
}) => (
  <div className="Testimony-wrapper">
    <div className="Testimony">
      <div>
        {description.map((richText, index) => (
          <Text description={richText} key={index} />
        ))}
      </div>
      <div className="Testimony-profile">
        <img className="Testimony-logo" src={companyLogo} alt={companyName} />
        <div className="Testimony-person">
          <p className="Testimony-personName">{person.name}</p>
          <p className="Testimony-occupation">{person.occupation}</p>
        </div>
      </div>
    </div>
  </div>
);
