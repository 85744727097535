import React from 'react';
import {
  AbstractTexture,
  Opacity,
} from '../../components/AbstractTexture/AbstractTexture';
import {CircleIcon, Size, Theme} from '../../components/CircleIcon/CircleIcon';
import {useIcon} from '../../useIcon';
import './Approach.css';

const approachPoints = [
  {
    title: 'Qualité',
    icon: 'policy',
    description:
      'Le code de qualité est un investissement pour votre entreprise : clair et maintenable, il vous permet de faire évoluer facilement votre application.',
  },
  {
    title: 'Formation de vos équipes',
    icon: 'school',
    description:
      'Ma démarche s’inscrit dans la durée : en plus d’intervenir ponctuellement, je peux transmettre mes savoirs-faire techniques à vos équipes.',
  },
  {
    title: 'Productivité',
    icon: 'timer',
    description:
      'Chaque process effectué manuellement par vos équipes est une perte de temps et induit un risque d’erreur. J’automatise vos process pour vous faire gagner en productivité.',
  },
];

const Icon: React.FC<{name: string}> = ({name}) => {
  const path = useIcon(name);
  return <img className="Approach-icon" src={path} alt={name} />;
};

export const Approach: React.FC = () => {
  return (
    <section className="Approach" id="Approche">
      <h2 className="Approach-title">Approche</h2>
      <ul className="Approach-list">
        {approachPoints.map(point => (
          <li key={point.title} className="Approach-step">
            <CircleIcon
              theme={Theme.PURPLE}
              iconContent={
                <AbstractTexture random={true} opacity={Opacity.High}>
                  <Icon name={point.icon} />
                </AbstractTexture>
              }
              size={Size.Big}
            />
            <h3 className="Approach-elementTitle">{point.title}</h3>
            <p className="Approach-description">{point.description}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};
