import {graphql, useStaticQuery} from 'gatsby';

interface IconQueryResult {
  icons: {
    edges: ReadonlyArray<{
      node: {
        publicURL: string;
        base: string;
      };
    }>;
  };
}

export function useIcon(icon: string): string | undefined {
  const iconList: IconQueryResult = useStaticQuery(graphql`
    query {
      icons: allFile(filter: {relativePath: {glob: "*.svg"}}) {
        edges {
          node {
            publicURL
            base
          }
        }
      }
    }
  `);
  const iconNodeUrl = iconList.icons.edges.find(
    ({node}) => icon && node.base.includes(icon)
  );

  if (iconNodeUrl) {
    return iconNodeUrl.node.publicURL;
  }
  return;
}
