import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {Approach} from '../sections/Approach/Approach';
import {Contact} from '../sections/Contact/Contact';
import {Home} from '../sections/Home/Home';
import {Services} from '../sections/Services/Services';
import {Testimonials} from '../sections/Testimonials/Testimonials';
import {Whoami} from '../sections/Whoami/Whoami';
import {WorkTogether} from '../sections/WorkTogether/WorkTogether';

const IndexPage = () => (
  <Layout>
    <SEO title="Consulting et développement logiciel" />
    <Home />
    <Services />
    <Approach />
    <WorkTogether />
    <Whoami />
    <Testimonials />
    <Contact />
  </Layout>
);

export default IndexPage;
